import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { TOOLTIP_POST_PO_AMEND_HEADER_NEED_BY_DATE } from "../../../../constants/tooltipText";
import { freeTextFormProps } from "../../../../styled/props/textFieldProps";
import { updateDate } from "../../../../utils/createRequisitionUtils";
import { headerFormGridColumnProps } from "./headerFormGridLayout";

const Section1General = ({ updateHeader, disabled }) => {
    const headerForm = useSelector(
        (state) => state.createRequisition.iBuyRequisition.requisitionHeaderForm
    );
    return (
        <Grid {...headerFormGridColumnProps}>
            <Grid item xs={12}>
                <Typography variant="h5" color="text.subheader">
                    General
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...freeTextFormProps}
                    label="Requisition Description (Max 240 Characters)"
                    multiline
                    onChange={(e) =>
                        updateHeader(e.target.value, "HEADER_DESCRIPTION")
                    }
                    placeholder="Description..."
                    value={headerForm.HEADER_DESCRIPTION}
                    disabled={disabled}
                    inputProps={{ maxLength: 240 }}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...freeTextFormProps}
                    label="Justification (Max 480 Characters)"
                    multiline
                    onChange={(e) =>
                        updateHeader(e.target.value, "JUSTIFICATION")
                    }
                    placeholder="Justification..."
                    value={headerForm.JUSTIFICATION}
                    disabled={disabled}
                    inputProps={{ maxLength: 480 }}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <Tooltip
                    placement="top"
                    title={TOOLTIP_POST_PO_AMEND_HEADER_NEED_BY_DATE(disabled)}
                >
                    <span>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                disablePast={disabled ? false : true}
                                label="Need By Date*"
                                inputFormat="dd/MM/yyyy"
                                onChange={(date) =>
                                    updateHeader(
                                        updateDate(date),
                                        "NEED_BY_DATE"
                                    )
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        {...freeTextFormProps}
                                        fullWidth={false}
                                    />
                                )}
                                minDate={new Date()}
                                value={headerForm.NEED_BY_DATE}
                                disabled={disabled}
                            />
                        </LocalizationProvider>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    {...freeTextFormProps}
                    label="Note to Buyer (Max 480 Characters)"
                    multiline
                    onChange={(e) =>
                        updateHeader(e.target.value, "NOTE_TO_BUYER")
                    }
                    placeholder="Note to Buyer..."
                    value={headerForm.NOTE_TO_BUYER}
                    disabled={disabled}
                    inputProps={{ maxLength: 480 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...freeTextFormProps}
                    label="Note to Approver (Max 448 Characters)"
                    multiline
                    onChange={(e) =>
                        updateHeader(e.target.value, "NOTE_TO_APPROVER")
                    }
                    placeholder="Note to Approver..."
                    value={headerForm.NOTE_TO_APPROVER}
                    disabled={disabled}
                    inputProps={{ maxLength: 448 }}
                />
            </Grid>
        </Grid>
    );
};

Section1General.propTypes = {
    updateHeader: PropTypes.func,
    disabled: PropTypes.any,
};

export default Section1General;
